<template>
  <!-- 搜索专题 -->
  <div class="container">
    <!-- 列表 -->
    <div class="list">
        <div style="width:100%">
            <V-OneTheme class="widths" :list="list"  @detail-click="gotoDetail" ></V-OneTheme>
        </div>
    </div>
    <br/>
    <!-- 分页 -->
    <div class="page-nav">
        <el-pagination
            background
            layout="prev, pager, next"
            :hide-on-single-page="hidePageNav"
            :total="totlePage"
            :page-size="pageSize"
            @prev-click="prevPage" @next-click="nextPage"  @current-change="currentPage">
        </el-pagination>
    </div>
  </div>

</template>

<script>
import OneTheme from "@/components/one-theme/type-one/index.vue";
import {DELETE,QUERY}  from '@/services/dao.js';
import { showTimeFormat,formatDate } from '@/js/common';
export default {
  data(){
    return{
      hidePageNav:true,
      pageNum:0,
      pageSize:12,
      totlePage:0,
      list:[],
    }
  },
  created: function(){
        this.init();
  },
  methods:{
          //初始化
      init(){
            this.searchWord=this.$route.query.searchWord;
            this.searchInfo(this.pageSize,0);
      },
      //前一页
      prevPage(val){
          this.pageNum=val-1;
          this.searchInfo(this.pageSize,this.pageSize*this.pageNum);
      },
      //下一页
      nextPage(val){
          this.pageNum=val-1;
          this.searchInfo(this.pageSize,this.pageSize*this.pageNum);
      },
      //当前页
      currentPage(val){
          this.pageNum=val-1;
          this.searchInfo(this.pageSize,this.pageSize*this.pageNum);
      },
      gotoDetail(id){
        this.$router.push({path:'/themeDetail', query: {
            id: id
        }});
      }
      ,
      async  searchInfo(limit,offset){
          let info=  await  QUERY("post","",'Theme(limit:  '+limit+', offset: '+offset+', where: {}) {    id    createTime    content    img    title  }  Theme_aggregate {    aggregate {      count    }  }');
          this.list.splice(0,this.list.length);
          for(let i=0;i<info.data.Theme.length;i++){
              this.list.push({
                  id:info.data.Theme[i].id,
                  img: this.api.LoginURL.concat(info.data.Theme[i].img),
                  title:info.data.Theme[i].title,
                  intro:info.data.Theme[i].content,
                  time:showTimeFormat(info.data.Theme[i].createTime),
              });
          }
          this.totlePage=info.data.Theme_aggregate.aggregate.count;
      }
  },
  components:{
    'V-OneTheme':OneTheme
  }
}
</script>

<style scoped>
  .container{
    width: 75%;
    /* border: 1px solid red; */
    margin:auto;
    padding-left: 4%;
    padding-top:20px;
    /* display: inline-block; */
    
  }
   .container .list{
     /* display: inline-block; */
     width: 100%;
     /* border: 1px solid forestgreen; */
     /* padding-left: 4%; */
     margin: auto;
   }
  /* .container .list,.container .page-nav{
     width: 100%;
     display: inline-block;
 
   } */
  .container>div{
    display: inline-block;
  } 
  .list>div{
    /* padding: 10px; */
    display: inline-block;
  }
  .widths{
    width: 100%;
    /* border: 1px solid darkcyan; */
    /* float: left; */
  }
</style>